import React, { useState, useEffect } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import "../helper.css";

const Extend = ({ projectId }) => {

    const [projectDetails, setProjectDetails] = useState();

    const realId = projectId.slice(-2);

    const extendProject = async (id) => {
        await axios.put('api/projects/ExtendProject/' + id)
            .then(res => console.log(res.data));
    };

    const getProject = async (id) => {

        const data = await axios.get('api/projects/' + id);
        setProjectDetails(data);
    }

    const handleExtend = async (id) => {
        await extendProject(id);
    };


    const addYears = (date, years) => {
        let result = new Date(date);
        result.setYear(result.getFullYear() + years);
        return result;
    };

    useEffect(() => {
        getProject(realId);
        handleExtend(projectId);
    }, []) // Empty so it gets called only once!
    return (
        <div id="project">
            {!!projectDetails && (
                <>
                    <div id="mainProjectBox">
                        <div className="projectTopHeader">Your project, <span className="projectTitle">{projectDetails?.data?.title}</span>, has now been extended to <span className="projectExpiryDate">{addYears(projectDetails?.data?.expiryDate, 1).toDateString()}</span></div>
                        <div className="projectWrapper">
                            <div className="projectData" style={{ backgroundColor: "Gainsboro" }}>
                                <div className="projectHeading">Title</div>
                                <div className="projectHeading">Posting Date</div>
                                <div className="projectHeading">Original Expiry Date</div>
                                <div className="projectHeading">New Expiry Date</div>
                                <div className="projectHeading">Description</div>
                            </div>
                            <div className="projectData" style={{ backgroundColor: "White" }}>
                                <div className="projectHeading">{projectDetails?.data?.title}</div>
                                <div className="projectHeading">{addYears(projectDetails?.data?.postDate, 0).toDateString()}</div>
                                <div className="projectHeading">{addYears(projectDetails?.data?.expiryDate, 0).toDateString()}</div>
                                <div className="projectHeading">{addYears(projectDetails?.data?.expiryDate, 1).toDateString()}</div>
                                <div className="projectHeading">{projectDetails?.data?.description}</div>
                            </div>
                        </div>
                        <div className="projectBottomHeader">Please email Suzann Beardsley(<a href='mailto:suzann@medschool.pitt.edu'>suzann@medschool.pitt.edu</a>) with any questions.<br /></div>
                    </div>
                </>
            )}
            <div id="updateProject">
                {/*<Button onClick={() => handleExtend(projectId)}>Click here to extend your project</Button>*/}
                {/*<div>Thank you for extending your project!</div>*/}
            {/*    <div><a href="http://localhost:55555/">Click here to close</a> this window</div>*/}
            </div>
        </div>
    );
}

export default Extend;