import React from "react";
import ReactDOM from "react-dom";
import Header from "./Header/Header";
import Extend from "./Client/Extend";

import './Table/index.css';
let search = window.location.search;
let params = new URLSearchParams(search);
let action = params.get('REF');
let id = params.get('578A3D4');


if (action === "xZaGiCzGDkiYMSHiUpFUEQ") {
    ReactDOM.render(
        <React.Fragment>
            <Extend projectId={id} />
        </React.Fragment>,
        document.getElementById('extend')
    )
} else {
    ReactDOM.render(
        <React.Fragment>
            <Header />
        </React.Fragment>,
        document.getElementById('root')
    )
}

