import React, { useState, useCallback } from "react";
import ResearchForm from "../ResearchForm";
import LoginForm from "../LoginForm";
import ResearchEntries from "../Table/ResearchEntries";
import ResearchEntriesAdmin from "../Admin/ResearchEntriesAdmin";
import ApprovalAdmin from "../Approval/ApprovalAdmin";
import firebase from '../firebaseConfig';
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();
const renderForm = () => {
    return (
        <ResearchForm />
    );
}

const renderTable = () => {
    return (
        <ResearchEntries />
    );
}

const renderApprovalTable = () => {
    return (
        <ApprovalAdmin />
    );
}

const RenderApprovalLink = ({ onClick, loginChosen, setLoginChosen }) => {
    function handleClick(ev) {
        ev.preventDefault();
        onClick && onClick()
    }
    return (
        <React.Fragment>
            <a id="approvalPage" style={{ color: "white" }} className="nav-link" href="#" onClick={handleClick}>Approval Admin <span className="sr-only"></span></a>
        </React.Fragment>
    );
}

const RenderTableLink = ({ onClick, loginChosen, setLoginChosen }) => {
    function handleClick(ev) {
        ev.preventDefault();
        onClick && onClick()
    }
    return (
        <React.Fragment>
            <a id="tablePage" style={{ color: "white" }} className="nav-link" href="#" onClick={handleClick}>Research Opportunities<span className="sr-only"></span></a>
        </React.Fragment>
    );
}

const RenderAdminLink = ({ onClick, loginChosen, setLoginChosen }) => {
    function handleClick(ev) {
        ev.preventDefault();
        onClick && onClick()
    }
    return (
        <React.Fragment>
            <a id="adminPage" style={{ color: "white" }} className="nav-link" href="#" onClick={handleClick}>Research Opportunities<span className="sr-only"></span></a>
        </React.Fragment>
    );
}


const RenderLoginLink = ({ onClick, loggedInStatus, returnToMain }) => {

    function handleClick(ev) {
        ev.preventDefault();
        if (!loggedInStatus) {
            onClick && onClick()
        } else {
            auth.signOut()
            alert('You have been logged out. ');
            returnToMain();
        }
    }
    if (!loggedInStatus) {
        return (
            <React.Fragment>
                <a id="approvalPage" style={{ color: "white" }} className="nav-link" href="#" onClick={handleClick}> Log In <span className="sr-only"></span></a>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <a id="approvalPage" style={{ color: "white" }} className="nav-link" href="#" onClick={handleClick}> Log Out <span className="sr-only"></span></a>
            </React.Fragment>
        );
    }
}

const Header = () => {

    const [showForm, setForm] = useState(false);
    const [showApprovalTable, setApprovalTable] = useState(false);
    const [showAdminTable, setAdminTable] = useState(false);
    const [showApprovalLink, setApprovalLink] = useState(false);
    const [showTable, setTable] = useState(true);
    const [showLoginPage, setLoginPage] = useState(false);
    const [loggedInStatus, setLoginStatus] = useState(false);
    const [oppChosen, setOppChosen] = useState(true);
    const [addChosen, setAddChosen] = useState(false);
    const [approveChosen, setApproveChosen] = useState(false);
    const [adminChosen, setAdminChosen] = useState(false);
    const [loginChosen, setLoginChosen] = useState();
    const [userEmail, setUserEmail] = useState('');
    const [loginLabel, setLoginLabel] = useState('Log In');

    auth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
            setLoginStatus(true);
            setUserEmail(firebaseUser.email);
        } else {
            setLoginStatus(false);
        }
    })


    //const handleLinkMenuClick = useCallback(e => { setGamerViewer(false) || (setGameCreator(true) && setChosen(true))});
    const handleTableClick = useCallback(e => { (setForm(false) || setApprovalTable(false) || setAdminTable(false) || setLoginPage(false)) || (setTable(true) || setOppChosen(true) || setAddChosen(false) || setApproveChosen(false) || setAdminChosen(false)) }, []);
    const handleAdminTableClick = useCallback(e => { (setForm(false) || setApprovalTable(false) || setAdminTable(true) || setLoginPage(false)) || (setTable(false) || setOppChosen(false) || setAddChosen(false) || setApproveChosen(false) || setAdminChosen(true)) }, []);
    const handleFormClick = useCallback(e => { (setTable(false) || setApprovalTable(false) || setAdminTable(false) || setLoginPage(false)) || (setForm(true) || setAddChosen(true) || setOppChosen(false) || setApproveChosen(false) || setAdminChosen(false)) }, []);
    const handleApprovalClick = useCallback(e => { (setTable(false) || setForm(false) || setLoginPage(false)) || (setApprovalTable(true) || setAdminTable(false) || setAdminChosen(false) || setApproveChosen(true) || setAddChosen(false) || setOppChosen(false)) }, []);
    const handleLoginClick = useCallback(e => { (setTable(false) || setForm(false) || setApprovalTable(false)) || setAdminTable(false) || setLoginPage(true) }, []);

    const onLogin = () => {
        setAdminTable(false);
        setAdminChosen(false);
        setApproveChosen(true);
        setTable(false);
        setApprovalTable(true);
        //setApproveChosen(true);
        setOppChosen(false);
        setLoginStatus(true);
        setLoginPage(false);
    }

    return (
        <div>
            <div className="header_wrapper_inline">
                <span className="loginSection">
                    {<RenderLoginLink
                        onClick={handleLoginClick}
                        loginChosen={loginChosen}
                        setLoginChosen={setLoginChosen}
                        setLoginLabel={setLoginLabel}
                        loggedInStatus={loggedInStatus}
                        returnToMain={handleTableClick}
                        adminTable={handleAdminTableClick}
                    />}
                </span>
                <div className="containerUPSOM" style={{ backgroundColor: "#003594" }}>
                    <div className="navbar navbar-expand-sm">
                        <a className="navbar-brand pitt_shield" href="#">
                            <img src="../Images/logo.png" alt="University of Pittsburgh logo" style={{ width: "75%", height: "auto" }} />
                        </a>
                        <span className="site_logo" style={{ color: "white", fontSize: "1.85em", paddingRight: "8px", verticalAlign: "middle" }}>School of Medicine</span>
                        <span className="site_logo" style={{ color: "white", fontSize: "1.25em", borderLeft: "2px solid rgba(255,255,255,0.3)", paddingTop: "1px", paddingLeft: "8px", verticalAlign: "middle" }}>Research Opportunities</span>
                    </div>
                    <nav className="navbar navbar-expand-sm" style={{ padding: 0 }}>
                        <div id="primary-links">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className={`nav-item ${adminChosen ? 'current' : ''}`}>
                                    {loggedInStatus && <RenderAdminLink onClick={handleAdminTableClick} />}
                                </li>
                                <li className={`nav-item ${oppChosen ? 'current' : ''}`}>
                                    {!loggedInStatus && <RenderTableLink onClick={handleTableClick} />}
                                </li>
                                <li className={`nav-item ${addChosen ? 'current' : ''}`}>
                                    <a id="formPage" style={{ color: "white" }}  className="nav-link" href="#" onClick={handleFormClick}>Add an Opportunity <span className="sr-only">(current)</span></a>
                                </li>
                                <li className={`nav-item ${approveChosen ? 'current' : ''}`}>{loggedInStatus && <RenderApprovalLink onClick={handleApprovalClick} />} </li>
                                <li className={`nav-item ${loginChosen ? 'current' : ''}`}>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="containerUPSOM" style={{ backgroundColor: "white" }}>
                <div style={{ backgroundColor: "white" }}>
                    {showForm && <ResearchForm />}
                </div>
                <div style={{ backgroundColor: "white" }}>
                    {showTable && <ResearchEntries />}
                </div>
                <div style={{ backgroundColor: "white" }}>
                    {showAdminTable && <ResearchEntriesAdmin onClick={handleAdminTableClick} />}
                </div>
                <div style={{ backgroundColor: "white" }}>
                    {showApprovalTable && <ApprovalAdmin onClick={handleApprovalClick} />}
                </div>
                <div style={{ backgroundColor: "white" }}>
                    {showLoginPage && <LoginForm onLogin={onLogin} />}
                </div>
                <div id="portal-root"></div>
            </div>
        </div>
    );
}

export default Header;