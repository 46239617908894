// Helper styles for demo
import "./helper.css";
import Button from 'react-bootstrap/Button';
import { MoreResources, DisplayFormikState } from "./helper";
import axios from 'axios';
import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OptionList from "./DataComponents/departmentData.js";
import TraineeList from "./DataComponents/traineeData.js";
import LocationList from "./DataComponents/locationData.js";
import StatusData from "./DataComponents/statusData.js";
import ProjectTypeList from "./DataComponents/projectTypeData.js";
import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import styled from 'styled-components';

const Styles = styled.div`
  padding: 1rem;
  `

// create a variable to store the component instance
let recaptchaInstance;

// create a reset function
const resetRecaptcha = () => {
    recaptchaInstance.reset();
};

const stripHtmlAndCode = (str) => {
    return str.replace(/(<([^>]+)>)/ig, "");
};

const ResearchForm = () => (
    <div className="app">
        <Helmet>
            <script src="https://www.google.com/recaptcha/api.js" type="text/javascript" />
        </Helmet>
        <Formik
            initialValues={{
                projectTrainees: ["1"],
                email: "",
                name: "",
                phone: "",
                adminContact: "",
                title: "",
                postDate: new Date(),
                expiryDate: "",
                projectTypeId: "1",
                otherProjectTypeId: "",
                deptId: "1",
                otherDepartment: "",
                locationId: "1",
                description: "",
                status: "1",
                url: "",
                isEqualOpp: false,
                isHuman: false,
                recaptcha: ""
            }}

            onSubmit={(values, { setSubmitting, resetForm }) => {

                values.description = stripHtmlAndCode(values.description);
                //new Promise(resolve => setTimeout(resolve, 500));
                axios({
                    method: "POST",
                    url: "api/formprocessor",
                    data: JSON.stringify(values),
                    headers: { 'Content-Type': 'application/json; charset=utf-8' }
                });
                alert("Thank you for your submission. As soon as it has been approved, it will appear under the Research Opportunities tab. ");
                resetForm();
                resetRecaptcha();
            }}

            // onReset={({ resetForm, resetRecaptcha }) => {
            //     //resetForm();
            //     resetRecaptcha();
            // }}

            validationSchema={Yup.object().shape({
                projectTrainees: Yup.array().required("At least one project trainee is required"),
                email: Yup.string()
                    .email()
                    .required("Required"),
                title: Yup.string()
                    .min(1, "Please enter a title")
                    .max(280, 'Please enter no more than 280 characters for the title')
                    .required("Required"),
                postDate: Yup.date()
                    .required("Required")
                ,
                expiryDate: Yup.date()
                    .required("Required")
                    .when(
                        "postDate",
                        (postDate, schema) => postDate && schema.min(postDate))
                ,
                description: Yup.string()
                    .min(100, "Please enter a description with a minimum of 100 characters")
                    .max(4000, 'Please enter no more than 4000 characters')
                    .required("Required"),
                isEqualOpp: Yup.boolean().oneOf([true], 'Please check the agreement'),
                // isHuman: Yup.boolean().oneOf([true], 'Please answer the challenge'),
                recaptcha: Yup.string().required('Please answer the challenge')
            })}
        >

            {props => {

                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleSubmit,
                    handleReset,
                    setFieldValue
                } = props;

                return (
                    <Styles>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <div id="formInstructions" className="instructions">Please complete the form below to submit a project. Your entry will be reviewed by Medical Student Research Deans prior to posting.
                            You will be notified by email of approval of your post or asked to provide additional or revised information.
Please contact Suzann Beardsley (Suzann@medschool.pitt.edu) if you have any questions.</div>
                            <br />
                            <h5>Project Information</h5>

                            <div id="trainees">
                                <label htmlFor="projectTrainees" style={{ display: "block" }}>
                                    Project Trainee(s) (check all that apply)
                                {errors.projectTrainees && touched.projectTrainees && (
                                        <div className="input-feedback">{errors.projectTrainees}</div>
                                    )}
                                </label>
                                <TraineeList />
                            </div>

                            <label htmlFor="title" style={{ display: "block " }}>
                                Title of Research Opportunity
                            {errors.title && touched.title && (
                                    <div className="input-feedback">{errors.title}</div>
                                )}
                            </label>
                            <Field
                                id="title"
                                placeholder="Example: Kidney Dialysis Research"
                                type="text"
                                value={values.title}
                                className={
                                    errors.title && touched.title
                                        ? "text-input error"
                                        : "text-input"
                                }
                            />

                            <label htmlFor="postDate" style={{ display: "block " }}>
                                Start Date
                        </label>
                            <DatePicker
                                id="postDate"
                                name="postDate"
                                value={values.postDate}
                                onChange={date => setFieldValue('postDate', date)}
                                selected={values.postDate}
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                            />

                            <label htmlFor="expiryDate" style={{ display: "block " }}>
                                Expiration Date
                            {errors.expiryDate && touched.expiryDate && (
                                    <div className="input-feedback">{errors.expiryDate}</div>
                                )}
                            </label>
                            <DatePicker
                                id="expiryDate"
                                name="expiryDate"
                                value={values.expiryDate}
                                onChange={date => setFieldValue('expiryDate', date)}
                                selected={values.expiryDate}
                                dateFormat="MMMM d, yyyy"
                                className="form-control"
                            />

                            <label htmlFor="projectTypeId" style={{ display: "block " }}>
                                Types of Research
                        </label>

                            <Field
                                as="select"
                                name="projectTypeId"
                                value={values.projectTypeId}
                                style={{ display: 'block' }}
                            >
                                <ProjectTypeList />
                            </Field>

                            {errors.projectTypeId && touched.projectTypeId && (
                                <div className="input-feedback">{errors.projectTypeId}</div>
                            )}

                            <label htmlFor="deptId" style={{ display: "block " }}>
                                Department/Division
                            </label>
                            <Field
                                name="deptId"
                                as="select"
                                value={values.deptId}
                                style={{ display: 'block' }}
                            >
                                <OptionList />
                            </Field>
                            {errors.deptId && touched.deptId && (
                                <div className="input-feedback">{errors.deptId}</div>
                            )}

                            <label htmlFor="locationId" style={{ display: "block" }}>
                                Location
                            </label>
                            <Field
                                name="locationId"
                                as="select"
                                value={values.locationId}
                                style={{ display: 'block' }}
                            >
                                <LocationList />
                            </Field>
                            {errors.locationId && touched.locationId && (
                                <div className="input-feedback">{errors.locationId}</div>
                            )}

                            <label htmlFor="description" style={{ display: "block" }}>
                                Project Description
                            {errors.description && touched.description && (
                                    <div className="input-feedback">{errors.description}</div>
                                )}
                            </label>
                            <Field
                                name="description"
                                component="textarea"
                                placeholder="Please provide concise (max 4,000 character limit) description of the project including specific work to be done by trainee, time commitment,  potential outcomes and opportunities for dissemination of work” Be sure to include contact name and information, and the method by which you would prefer receiving applicants’ materials."
                                rows="20"
                                cols="200"
                                className={
                                    errors.description && touched.description
                                        ? "text-input error"
                                        : "text-input"
                                }
                            />
                            <br />
                            <br />
                            <h5>Mentor Information</h5>


                            <label htmlFor="name" style={{ display: "block" }}>
                                Name
                            {errors.name && touched.name && (
                                    <div className="input-feedback">{errors.name}</div>
                                )}
                            </label>
                            <Field
                                id="name"
                                placeholder="Enter your name"
                                type="text"
                                value={values.name}
                                className={
                                    errors.name && touched.name
                                        ? "text-input error"
                                        : "text-input"
                                }
                            />

                            <label htmlFor="email" style={{ display: "block" }}>
                                Email
                            {errors.email && touched.email && (
                                    <div className="input-feedback">{errors.email}</div>
                                )}
                            </label>
                            <Field
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                type="email"
                                value={values.email}
                                className={
                                    errors.email && touched.email
                                        ? "text-input error"
                                        : "text-input"
                                }
                            />

                            <label htmlFor="phone" style={{ display: "block" }}>
                                Phone
                        </label>
                            <Field
                                id="phone"
                                placeholder="Enter your phone number"
                                type="text"
                                value={values.phone}
                            />

                            <label htmlFor="adminContact" style={{ display: "block" }}>
                                Administrative contact name
                        </label>
                            <Field
                                id="adminContact"
                                placeholder="Enter the name of your administrative contact"
                                type="text"
                                value={values.adminContact}
                            />

                            <label htmlFor="url" style={{ display: "block" }}>
                                Relevant URL
                        </label>
                            <Field
                                id="url"
                                placeholder="e.g. faculty web page, etc"
                                type="text"
                                value={values.url}
                            />

                            <label htmlFor="url" style={{ display: "block" }}>
                                Project Status
                        </label>
                            <StatusData />

                            <div id="footer">
                                <div className="agreeControl">
                                    <label htmlFor="isEqualOpp" className="agreeText">
                                        <Field
                                            id="isEqualOpp"
                                            type="checkbox"
                                            name="isEqualOpp"
                                            className={
                                                errors.isEqualOpp && touched.isEqualOpp
                                                    ? "agreeText text-input error"
                                                    : "agreeText text-input"
                                            }
                                        />
                                        {errors.isEqualOpp && touched.isEqualOpp && (
                                            <div className="input-feedback">{errors.isEqualOpp}</div>
                                        )}
                                    </label>

                                    <div style={{ display: "inline" }}>By checking the box to the left, you hereby affirm your organization is an equal opportunity employer, offering employment without regard to race, creed, religion, color, sex, marital status, disability, public assistance status, national origin, age, veteran status, or sexual orientation.
                                    The University of Pittsburgh is committed to the policy that all persons shall have equal access to its programs, facilities, and employment without regard to race, color, creed, religion, national origin, sex, age, marital status, disability, public assistance status, veteran status, or sexual orientation
                                </div>
                                </div>

                                <div className="form-group">
                                    <label></label>
                                    <Recaptcha
                                        sitekey="6LeS8_IUAAAAALtQzwUgNV9ZfbZVzSlhvglg314U"
                                        ref={e => recaptchaInstance = e}
                                        render="explicit"
                                        theme="light"
                                        verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                        onloadCallback={() => { }}
                                    />
                                    {errors.recaptcha
                                        && touched.recaptcha && (
                                            <div className="input-feedback">{errors.recaptcha}</div>
                                        )}
                                </div>
                            </div>

                            <div style={{ display: "block" }}>
                                <Button
                                    variant="secondary"
                                    onClick={handleReset}
                                    //onClick={() => { handleReset(); resetRecaptcha() }}
                                    disabled={!dirty || isSubmitting}
                                >
                                    Reset
                            </Button>
                                <Button type="submit"
                                    variant="primary"
                                    onClick={resetRecaptcha}
                                >
                                    Submit
                            </Button>
                            </div>
                            {/* <DisplayFormikState {...props} /> */}
                        </form>
                    </Styles>
                );
            }}
        </Formik>

        {/* <MoreResources /> */}
    </div>
);

export default ResearchForm;

