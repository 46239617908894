import React from 'react';
import ReactDOM from 'react-dom';

const projectStatus = {
    1: 'Open',  //active
    2: 'Potential Opportunity', // Past Project
    3: 'Expired' // expired
}

const ProjectDetails = ({ titleVal, idVal, typeVal, mentorNameVal, mentorEmailVal, adminContactVal, deptVal, locationVal, statusNameVal, descVal, statusVal, postDateVal, expiryDateVal, hide }) => (


    ReactDOM.createPortal(
        <div id="portal_ProjectDetails">
            <div id="Project_Properties">
                <div id="Details_Heading">{titleVal}</div>
                <div id="Details_Close"><button className="main" onClick={hide} style={{ margin: 0 }}> X </button></div>
                <div className="grid-container">
                    <div className="grid-item">Posted on</div>
                    <div className="grid-item">Title</div>
                    <div className="grid-item">Type of Research</div>
                    <div className="grid-item">Mentor Name</div>
                    <div className="grid-item">Mentor Email</div>
                    <div className="grid-item">Admin Contact</div>
                    <div className="grid-item">Department</div>
                    <div className="grid-item">Location</div>
                    <div className="grid-item">Status</div>
                    <div className="grid-item">Expires on</div>
                    <div className="grid-item">Description</div>

                    <div className="grid-value">{postDateVal}</div>
                    <div className="grid-value">{titleVal}</div>
                    <div className="grid-value">{typeVal}</div>
                    <div className="grid-value">{mentorNameVal}</div>
                    <div className="grid-value">{mentorEmailVal}</div>
                    <div className="grid-value">{adminContactVal}</div>
                    <div className="grid-value">{deptVal}</div>
                    <div className="grid-value">{locationVal}</div>
                    <div className="grid-value">{projectStatus[statusVal]}</div>
                    <div className="grid-value">{expiryDateVal}</div>
                    <div className="grid-value textbox">{descVal}</div>
                </div>
                {/* <p><button onClick={hide}>Close</button></p> */}
            </div>
        </div>,
        document.getElementById('portal-root'),
        //document.getElementById('researchOppTable').style.filter = 'blur(5px)'
    )
)

export default ProjectDetails;