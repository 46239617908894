import React, { useState, useEffect } from 'react';
import axios from 'axios';

const OptionList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'api/departments',
            );
            //setData(result.data);
            setData(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {data.filter(item => item.groupById === 2).map((item, index) => (
                <option key={index} value={item.id} label={item.name} />
            ))}

            <optgroup label="Surgery"></optgroup>
            {data.filter(item => item.groupById === 3 && item.id !== 95).map((item, index) => (
                <option key={index} value={item.id} label={item.name} />
            ))}
            <optgroup label="Non UPSOM"></optgroup>
            {data.filter(item => item.groupById === 1 && item.id !== 95).map((item, index) => (
                <option key={index} value={item.id} label={item.name} />
            ))}
            <option value="95" label="Other" />
        </React.Fragment>

    );
}
export default OptionList;


