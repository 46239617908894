import { Formik, Field, Form, ErrorMessage } from 'formik';
import "../helper.css";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";
import OptionList from "../DataComponents/departmentData.js";
import LocationList from "../DataComponents/locationData.js";
import ProjectTypeList from "../DataComponents/projectTypeData.js";
import StatusData from "../DataComponents/statusData.js";
import DatePicker from "react-datepicker";

import styled from 'styled-components';

const Styles = styled.div`
  padding: 1rem;
  `

const EditForm = (props) => {
    const [projectData, setProjectData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [formData, setFormData] = React.useState([]);


    useEffect(() => {
        const fetchProjectData = async () => {
            const result = await axios(
                'api/projecttypes',
            );
            setProjectData(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchProjectData();
    }, []);

    useEffect(() => {
        const fetchLocationData = async () => {
            const result = await axios(
                'api/locations',
            );
            setLocationData(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchLocationData();
    }, []);


    useEffect(() => {
        const fetchDepartmentData = async () => {
            const result = await axios(
                'api/departments',
            );
            setDepartmentData(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchDepartmentData();
    }, []);

    const updateProject = async (values) => {
        await axios({
            method: "PUT",
            url: "api/projects/" + values.id,
            data: JSON.stringify(values),
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });

        var mentorUpdater = {
            id: values.mentorId,
            name: values.mentorName,
            email: values.mentorEmail,
            adminContact: values.adminContact
        };

        await axios({
            method: "PUT",
            url: "api/mentors/" + values.mentorId,
            data: JSON.stringify(mentorUpdater),
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });
    };


    return (
        ReactDOM.createPortal(
            <div id="portal_ProjectDetails">
                <div id="Details_Heading"></div>
                <div id="Details_Close"><button className="main" onClick={props.updateAndHide} style={{ margin: 0 }}> X </button></div>
                <Formik
                    initialValues={{ id: props.idVal, title: props.titleVal, projectTypeId: String(props.typeIdVal), deptId: String(props.deptIdVal), locationId: String(props.locationIdVal), 
                                     mentorName: String(props.mentorNameVal), mentorEmail: props.mentorEmailVal, adminContact: props.adminContactVal, departmentName: props.deptVal,
                                     locationName: props.locationVal, status: String(props.statusVal), description: props.descVal, postDate: props.postDateVal, expiryDate: props.expiryDateVal,
                                     url: props.urlVal, mentorPhone: props.phoneVal, mentorId: props.mentorIdVal, isApproved: props.isApprovedVal, isEqualOpp: props.isEqualOppVal,
                                     isRejected: props.isRejectedVal }}

                    onSubmit={ async(values, { setSubmitting }) => {
                        await updateProject(values);

                        props.updateAndHide();
                        alert("Project Saved.");
                    }}
                >
                    {({ errors, isSubmitting, values, setFieldValue }) => (
                    <Styles>
                        <Form>
                            <label htmlFor="title" style={{ display: "block " }}>
                                Title of Research Opportunity
                            </label>
                            <Field id="title" placeholder="Example: Kidney Dialysis Research" type="text" name="title"/>

                            <label htmlFor="expiryDate" style={{ display: "block " }}>
                                Expiration Date
                            </label>
                            <DatePicker
                                    id="expiryDate"
                                    name="expiryDate"
                                    value={values.expiryDate}
                                    selected={(values.expiryDate && new Date(values.expiryDate)) || null}
                                    onChange={val => {
                                        setFieldValue("expiryDate", val);
                                    }}
                                    dateFormat="MMMM d, yyyy"
                                    className="form-control"
                            />

                            <label htmlFor="projectTypeId" style={{ display: "block " }}>
                                Types of Research
                            </label>

                            <Field as="select" name="projectTypeId" style={{ display: 'block' }}>
                                {projectData.filter(item => item.id !== 6).map((item, index) => (
                                    <option key={index} value={item.id} label={item.name} />
                                ))}
                                <option value="6" label="Other" />
                            </Field>

                            <label htmlFor="deptId" style={{ display: "block " }}>
                                Department/Division
                                </label>
                            <Field name="deptId" as="select" style={{ display: 'block' }}>
                                {departmentData.filter(item => item.groupById === 2).map((item, index) => (
                                    <option key={index} value={item.id} label={item.name} />
                                ))}

                                <optgroup label="Surgery"></optgroup>
                                {departmentData.filter(item => item.groupById === 3 && item.id !== 95).map((item, index) => (
                                    <option key={index} value={item.id} label={item.name} />
                                ))}
                                <optgroup label="Non UPSOM"></optgroup>
                                {departmentData.filter(item => item.groupById === 1 && item.id !== 95).map((item, index) => (
                                    <option key={index} value={item.id} label={item.name} />
                                ))}
                                <option value="95" label="Other" />
                            </Field>

                            <label htmlFor="locationId" style={{ display: "block" }}>
                                Location
                            </label>
                            <Field name="locationId" as="select" style={{ display: 'block' }}>
                                {locationData.filter(item => item.id !== 5).map((item, index) => (
                                    <option key={index} value={item.id} label={item.name} />
                                ))}
                                <option value="5" label="Other" />
                            </Field>

                            <label htmlFor="description" style={{ display: "block" }}>
                                Project Description
                            </label>
                            <Field name="description" component="textarea" rows="10" cols="300"/>
                            <br />
                            <h5>Mentor Information</h5>


                            <label htmlFor="name" style={{ display: "block" }}>
                                Name
                            </label>
                            <Field id="mentorName" name="mentorName" placeholder="Enter your name" type="text"/>

                            <label htmlFor="email" style={{ display: "block" }}>
                                Email
                            </label>
                            <Field id="mentorEmail" name="mentorEmail" placeholder="Enter your email" type="email"/>

                            <label htmlFor="phone" style={{ display: "block" }}>
                                Phone
                            </label>
                            <Field id="mentorPhone" name="mentorPhone" placeholder="Enter your phone number" type="text" />

                            <label htmlFor="adminContact" style={{ display: "block" }}>
                                Administrative contact name
                            </label>
                            <Field id="adminContact" name="adminContact" placeholder="Enter the name of your administrative contact" type="text" />

                            <label htmlFor="url" style={{ display: "block" }}>
                                Relevant URL
                            </label>
                            <Field id="url" name="url" placeholder="e.g. faculty web page, etc" type="text" />

                            <label htmlFor="url" style={{ display: "block" }}>
                                Project Status
                            </label>

                            <label>
                                <Field type="radio" className="eligibleType" name="status" value="1" />
                                            Open
                                </label>
                            <label>
                                <Field type="radio" className="eligibleType" name="status" value="2" />
                                            Potential Opportunity
                            </label>
                            <label>
                                <Field type="radio" className="eligibleType" name="status" value="3" />
                                            Expired
                            </label>
                            <label htmlFor="approvalStatus" className="inline">
                                <Field type="checkbox" className="isApproved" name="isApproved"  />
                                                Approved?
                            </label>
                            <br /><br />
                            <div style={{ display: "block" }}>
                                <Button type="submit" variant="primary">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Styles>
                    )}
                 </Formik>
            </div>,
            document.getElementById('portal-root')
        )
    );
};

export default EditForm;