import axios from "axios";

const fetchData = async (showExpired, showPotential) => {
    let apiUri = "";
    apiUri = "api/projects/GetAllProjects/"
    //let apiUri = "";
    //if (showExpired && !showPotential) {
    //    apiUri = "api/projects/GetProjectsExpired/"
    //} else if (showPotential && showExpired) {
    //    apiUri = "api/projects/GetAllProjects/"
    //} else if (showPotential && !showExpired) {
    //    apiUri = "api/projects/GetPotentialProjects/"
    //} else {
    //    apiUri = "api/projects/"
    //}
  const result = await axios(apiUri, {
    params: {
      isDeleted: false
    }
  });

  // return the result
  return result;
};

export default fetchData;