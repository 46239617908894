import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'api/locations',
            );
            //setData(result.data);
            setData(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {data.filter(item => item.id !== 5).map((item, index) => (
                <option key={index} value={item.id} label={item.name} />
            ))}
            <option value="5" label="Other" />
        </React.Fragment>

    );
}
export default LocationList;