import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Field } from "formik";
import "../helper.css";

const StatusData = () => {

    return (
        <React.Fragment>
            <div>
                <label>
                    <Field type="radio" className="eligibleType" name="status" value="1" />
                    Open
                </label>
                <label>
                    <Field type="radio" className="eligibleType" name="status" value="2" />
                    Potential Opportunity 
                </label>
                <label>
                    <Field type="radio" className="eligibleType" name="status" value="3" />
                    Expired
                </label>
            </div>
        </React.Fragment>

    );
}
export default StatusData;