
import * as firebase from 'firebase/app';
// Your web app's Firebase configuration
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBT0BLjbIfikfh7rLUxT4yYNmTTRh9A2ac",
    authDomain: "letresearchauth.firebaseapp.com",
    databaseURL: "https://letresearchauth.firebaseio.com",
    projectId: "letresearchauth",
    storageBucket: "letresearchauth.appspot.com",
    messagingSenderId: "657769549011",
    appId: "1:657769549011:web:84100eb91fd9438c549826",
    measurementId: "G-H72Y2BELD9"
};
// Initialize Firebase
initializeApp(firebaseConfig);
export default firebase;