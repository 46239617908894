import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Field } from "formik";
import "../helper.css";

const TraineeList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                'api/trainees',
            );
            setData(result.data);
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <div id="trainees">
                <label className="inline">
                    <Field type="checkbox" className="eligibleType" name="projectTrainees" value="1" />
                    All
                </label>
                <label className="inline">
                    <Field type="checkbox" className="eligibleType" name="projectTrainees" value="2" />
                    Medical Student
                </label>
                <label className="inline">
                    <Field type="checkbox" className="eligibleType" name="projectTrainees" value="3" />
                    Resident
                </label>
                <label className="inline">
                    <Field type="checkbox" className="eligibleType" name="projectTrainees" value="4" />
                    Fellow
                </label>
            </div>
        </React.Fragment>

    );
}
export default TraineeList;