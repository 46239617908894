import React, { useState, useEffect } from 'react';
import { Formik, Form, useField } from 'formik';
import Button from 'react-bootstrap/Button';
import firebase from './firebaseConfig';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword  } from "firebase/auth";
import styled from 'styled-components';

const auth = getAuth();

const Styles = styled.div`
  padding: 1rem;

`
const MyTextInput = ({ label, ...props }) => {

    const [field] = useField(props);
    return (
        <React.Fragment>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input className="text-input" {...field} {...props} />
        </React.Fragment>
    );
};

const LoginMessage = () => (
    <div id="login" className="login-status">
        You have successfully logged in!
    </div>
)

// And now we can use these
const LoginForm = (props) => {
    const [loggedIn, setLogin] = useState(false);
    const [showLoginSuccess, setLoginSuccess] = useState(false);
    useEffect(() => [loggedIn]);
    return (
        <div>
            <Styles>
                <br />
                <h5>Approval Login</h5>
                <Formik
                    initialValues={{
                        password: '',
                        email: ''
                    }}

                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            signInWithEmailAndPassword(auth, values.email, values.password)
                                .then(function (firebaseUser) {
                                    // Success 
                                    setLogin(true);
                                    setLoginSuccess(true);
                                    auth.onAuthStateChanged((firebaseUser) => {
                                        if (firebaseUser) {
                                            setLogin(true);
                                        } else {
                                            setLogin(false);
                                        }
                                    })
                                    props.onLogin();
                                })
                                .catch(function (error) {
                                    // Handle Errors here.
                                    var errorCode = error.code;
                                    var errorMessage = error.message;
                                    console.log("error: ", error);
                                    console.log("Firebase Error: ", errorMessage);
                                    // ...
                                });
                            actions.setSubmitting(false);

                        }, 1000);
                    }}
                >
                    <Form>
                        <MyTextInput
                            label="Email Address"
                            name="email"
                            type="email"
                            placeholder="youremail@pitt.edu"
                        />
                        <MyTextInput
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="password..."
                        />

                        <br />
                        <Button type="submit"
                            variant="primary">
                            Login
                    </Button>
                    </Form>
                </Formik>
                {showLoginSuccess ? <LoginMessage /> : null}
            </Styles>
        </div>
    );
};

export default LoginForm;